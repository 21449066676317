<template>
  <div class="home ">
    <v-container class="">
      <v-row class="d-flex">
        <v-col cols="12" sm="6" md="4" lg="3" class="pl-8 pr-8" v-for="item in menu" :key="item.nombre">
          <menu-item :itemMenu="item"></menu-item>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    const rol = JSON.parse(localStorage.LCInternet).user.rol;

    this.modules.forEach((e) => {
    if (e.users.includes(rol)) {
      delete e.users;
      this.menu.push(e);
    }
  });
  },
  components: {
    MenuItem: () => import("../components/MenuItem"),
  },
  data: () => ({
    modules: [
      {
        name: "CLIENTES",
        icon: "icon-customers",
        to: "Clients",
        users: ["admin", "cobrador","visualizador"],
      },
      {
        name: "CLIENTE TEMPORAL",
        icon: "icon-menu-internet-movil",
        // to: "InternetMovil",
        to: "MobileClients",
        users: ["admin", "cobrador","visualizador"],
      },
      {
        name: "ANTENAS",
        icon: "icon-packages",
        to: "Antennas",
        users: ["admin"],
      },
      {
        name: "CORTE DE CAJA",
        icon: "icon-bill",
        to: "CorteCaja",
        users: ["admin", "cobrador","visualizador"],
      },
      /*
      {
        name: "ROLES",
        icon: "IconUsers",
        to: "Roles",
        users: ["admin", "cobrador","visualizador"],
      },
      */
    ],
    menu: [],
  }),
};
</script>

<style lang="scss" scoped>
  .home {
    height: 100%;
    background-color: #f0f0f0;
    .menu-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      align-items: center;
      gap: 1rem;
      height: 100%;
    }
  }
</style>
