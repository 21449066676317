export const setCredentialsLocalStorage = async (credentials) => {
  const LCInternet = {};
  LCInternet.token = credentials.token;
  LCInternet.user = credentials.user;

  localStorage.setItem("LCInternet", JSON.stringify(LCInternet));
};

export const getToken = () => {
  try {
    const lcInternet = localStorage.getItem("LCInternet"); // Obtener el valor de localStorage
    if (!lcInternet) {
      throw new Error("LCInternet no está definido en localStorage.");
    }
    const parsedData = JSON.parse(lcInternet); // Intentar parsear el JSON
    if (!parsedData.token) {
      throw new Error("El objeto parseado no contiene un token.");
    }
    return parsedData.token; // Retornar el token si existe
  } catch (error) {
    console.error("Error obteniendo el token:", error.message);
    return null; // O algún valor por defecto
  }
};
