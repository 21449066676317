import RolesRepository from "../repository/Roles.repository";

export default class Roles {
  /**
   * Obtiene todos los usuarios.
   * @returns {Promise} Promesa que se resuelve con los datos de los usuarios.
   */
  static async getAllUsers() {
    return new Promise((resolve, reject) => {
      RolesRepository.getAllUsers()
        .then((res) => {
          resolve(res.data); // Retorna los datos de los usuarios
        })
        .catch((err) => {
          reject(err); // Maneja el error en caso de fallo
        });
    });
  }

  /**
   * Actualiza el rol de un usuario.
   * @param {Object} roleData - Objeto con la información del rol a actualizar.
   * @returns {Promise} Promesa que se resuelve con la respuesta del servidor.
   */
  static async updateRole(roleData) {
    return new Promise((resolve, reject) => {
      RolesRepository.updateRole(roleData)
        .then((res) => {
          console.log(res); // Imprime el resultado para depuración
          resolve(res.data); // Retorna los datos de la actualización
        })
        .catch((err) => {
          console.log(err); // Imprime el error para depuración
          reject(err); // Maneja el error en caso de fallo
        });
    });
  }

  /**
   * Obtiene el rol y username de un usuario específico.
   * @param {String} username - El nombre de usuario a buscar.
   * @returns {Promise} Promesa que se resuelve con los datos del rol y username.
   */
  static async getUserRoleByUsername(username) {
    return new Promise((resolve, reject) => {
      RolesRepository.getUserRoleByUsername(username)
        .then((res) => {
          resolve(res.data); // Retorna los datos de rol y username
        })
        .catch((err) => {
          reject(err); // Maneja el error en caso de fallo
        });
    });
  }
}
